import classNames from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';

import { toCountdownParts } from '@tb-core/helpers/server/datetime';

import styles from './styles.module.scss';

export interface CountdownTimerProps {
    className?: string;
    colorText?: string; // hex
    countdownEndCallback?: () => void;
    days: number;
    hours: number;
    label?: string | null;
    minutes: number;
    seconds: number;
}

const CountdownTimer = ({
    className,
    colorText,
    countdownEndCallback,
    days,
    hours, // 24hr format 12:30 17:30
    label,
    minutes,
    seconds
}: CountdownTimerProps) => {
    const setBaseTime = (
        days: number,
        hours: number,
        minutes: number,
        seconds: number
    ) => ({
        currentDays: String(days).padStart(1, '0'),
        currentHours: String(hours).padStart(1, '0'),
        currentMinutes: String(minutes).padStart(1, '0'),
        currentSeconds: String(seconds).padStart(1, '0'),
        currentTotalSeconds: 0
    });
    const [countdownInterval, setCountdownInterval] = useState(0);
    const [
        {
            currentDays,
            currentHours,
            currentMinutes,
            currentSeconds,
            currentTotalSeconds
        },
        setTime
    ] = useState(
        setBaseTime(
            Number(days),
            Number(hours),
            Number(minutes),
            Number(seconds)
        )
    );

    const dayLabel = currentDays === '1' ? 'day' : 'days';
    const hrLabel = currentHours === '1' ? 'hr' : 'hrs';

    useEffect(() => {
        setTime(
            setBaseTime(
                Number(days),
                Number(hours),
                Number(minutes),
                Number(seconds)
            )
        );
    }, [days, hours, minutes, seconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            const totalSeconds: number =
                Number(currentDays) * 24 * 60 * 60 +
                Number(currentHours) * 60 * 60 +
                Number(currentMinutes) * 60 +
                Number(currentSeconds);

            const secondsCountDown = Number(
                totalSeconds <= 0 ? 0 : totalSeconds - 1
            );

            const {
                days: newDays,
                hours: newHours,
                minutes: newMinutes,
                seconds: newSeconds
            } = toCountdownParts(secondsCountDown);

            setTime(setBaseTime(newDays, newHours, newMinutes, newSeconds));

            if (totalSeconds === 0) {
                clearInterval(interval);
                clearInterval(countdownInterval);
                setCountdownInterval(0);

                if (countdownEndCallback) {
                    countdownEndCallback();
                }
            }
        }, 1000);

        setCountdownInterval(Number(interval));

        return () => {
            clearInterval(interval);
        };
    }, [
        currentDays,
        currentHours,
        currentMinutes,
        currentSeconds,
        currentTotalSeconds
    ]);

    const textColor: CSSProperties | undefined = colorText
        ? {
              color: colorText
          }
        : undefined;

    const countdownTimerAvailable =
        !isNaN(days) && !isNaN(hours) && !isNaN(minutes) && !isNaN(seconds);

    return (
        <>
            {countdownTimerAvailable && (
                <div
                    className={classNames(styles.countdown, className)}
                    style={textColor}
                >
                    <fieldset>
                        <legend>
                            {label && (
                                <span
                                    className={classNames(
                                        styles['countdown-label']
                                    )}
                                >
                                    {label}
                                </span>
                            )}
                        </legend>
                        <p className={classNames(styles['countdown-text'])}>
                            {currentDays !== '0' && (
                                <span>
                                    {currentDays}
                                    <span>{dayLabel}</span>
                                </span>
                            )}
                            <span>
                                {currentHours}
                                <span>{hrLabel}</span>
                            </span>
                            <span>
                                {currentMinutes}
                                <span>min</span>
                            </span>
                            {currentDays === '0' && (
                                <span>
                                    {currentSeconds}
                                    <span>sec</span>
                                </span>
                            )}
                        </p>
                    </fieldset>
                </div>
            )}
        </>
    );
};

export default CountdownTimer;
