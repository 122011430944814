import classNames from 'classnames';

import Hero from '@tb-core/components/container/banners/hero';
import PromoHeroContent from '@tb-core/components/styled/banners/promo-hero/promo-hero-content';
import { getThemeFont } from '@tb-core/components/styled/modules/helpers/get-theme-fonts';
import StandardHeroFooter from '@tb-core/components/styled/standard-hero-footer';
import { PromoModuleProps } from '@tb-core/types';

import styles from './styles.module.scss';

const PromoHero = ({
    backgroundImage,
    className,
    colorTheme,
    style,
    ...props
}: PromoModuleProps) => {
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        ...style
    };

    const themeFontClassName = getThemeFont(colorTheme?.font);

    return (
        <Hero
            backgroundImage={backgroundImage}
            body={<PromoHeroContent {...{ ...props, colorTheme }} />}
            className={classNames(
                styles['standard-hero'],
                themeFontClassName,
                className
            )}
            footer={<StandardHeroFooter {...{ ...props, colorTheme }} />}
            style={theme}
        />
    );
};

export default PromoHero;
