import {
    DatadogAttribute,
    DatadogDictionaryValue
} from '@tb-core/types/data-dog.d';

export default class DatadogAttributesWrapper {
    public list: DatadogAttribute[] = [];
    public shouldSend: boolean = false;
    public reason: string | undefined;

    constructor(
        list: DatadogAttribute[],
        shouldSend: boolean,
        reason?: string
    ) {
        this.list = list;
        this.shouldSend = shouldSend;

        if (reason) {
            this.reason = reason;
        }
    }

    appendDictionary(data: Record<string, DatadogDictionaryValue>) {
        for (const [key, value] of Object.entries(data)) {
            this.list.push({
                logName: key,
                metadataName: key,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value
            });
        }
    }

    appendAttributes(data: DatadogAttribute[] | DatadogAttribute) {
        if (Array.isArray(data)) {
            this.list = [...this.list, ...data];
        } else {
            this.list.push(data);
        }
    }

    attributesInLogs(): DatadogAttribute[] {
        return this.list.filter(item => item.shouldUseInLog);
    }

    attributesInSession(): DatadogAttribute[] {
        return this.list.filter(item => item.shouldUseInSession);
    }

    getMatchingValue(key: string): DatadogDictionaryValue {
        const i = this.list.findIndex(
            item => item.logName === key || item.metadataName === key
        );

        if (i !== -1) {
            return this.list[i].value;
        } else {
            return undefined;
        }
    }

    removeValue(forKey: string) {
        const i = this.list.findIndex(
            item => item.logName === forKey || item.metadataName === forKey
        );

        if (i !== -1) {
            this.list.splice(i, 1);
        }
    }

    update(key: string, value: DatadogDictionaryValue) {
        const i = this.list.findIndex(
            item => item.logName === key || item.metadataName === key
        );

        if (i !== -1) {
            const newData = this.list[i];
            newData.value = value;
            this.list.splice(i, 1);
            this.list.push(newData);
        }
    }
}
