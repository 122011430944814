import GlobalHeader from '@tb-core/components/container/headers/global-header';
import LoyaltyModals from '@tb-core/components/container/loyalty-modals';
import PageProvider, {
    PageContextProps
} from '@tb-core/components/context/webpage';
import WebpageLayout from '@tb-core/components/layout/webpage';
import BottomBody from '@tb-core/components/regions/bottom-body';
import DocumentHeadRegion from '@tb-core/components/regions/document-head';
import FooterRegion from '@tb-core/components/regions/footer';
import LeftAsideRegion from '@tb-core/components/regions/left-aside';
import LoyaltyAsideRegion from '@tb-core/components/regions/loyalty-aside';
import TopBody from '@tb-core/components/regions/top-body';
import HomeMain from '@tb-public/components/container/home-main';

const HomePage = (props: PageContextProps) => (
    <PageProvider {...props}>
        <WebpageLayout
            regions={{
                bottomBody: (
                    <BottomBody>
                        <LoyaltyModals />
                    </BottomBody>
                ),
                footer: <FooterRegion />,
                head: <DocumentHeadRegion />,
                header: <GlobalHeader />,
                leftAside: <LeftAsideRegion />,
                main: <HomeMain />,
                rightAside: <LoyaltyAsideRegion />,
                topBody: <TopBody inlineSvgs={props.content.pageData?.icons} />
            }}
        />
    </PageProvider>
);

export default HomePage;
