import { useEffect, useRef, useState } from 'react';

import DaypartStandardHero from '@tb-core/components/container/banners/daypart-standard-hero';
import PromoHero from '@tb-core/components/styled/banners/promo-hero';
import { isEmptyObject } from '@tb-core/helpers/object';
import { isTimeInRange } from '@tb-core/helpers/server/datetime';
import usePageContext from '@tb-core/hooks/use-page-context';
import { usePageIsVisible } from '@tb-core/hooks/window/use-page-visibility';
import { getPromoCurrentTime } from '@tb-core/providers/get-promo-current-time';
import {
    GenericContentProps,
    PromoContentProps,
    PromoModuleProps
} from '@tb-core/types';

const PromotionStandardHero = () => {
    const { contentGroupCollection } = usePageContext().content.pageData;
    const { topicMeta } = usePageContext().content.pageData;
    const promoStartLabel = topicMeta?.generic?.promoStartLabel || '';
    const promoRemainingLabel = topicMeta?.generic?.promoRemainingLabel || '';
    const contentModules: PromoContentProps[] = contentGroupCollection.items as PromoContentProps[];
    const heroList: PromoContentProps[] = contentModules.filter(
        module => module?.type?.toLowerCase() === 'hero'
    );
    const heroListPromo: PromoContentProps[] = [];
    const heroListStandard: PromoContentProps[] = [];

    heroList.map(hero =>
        hero?.promoStartDateTime === null || hero?.promoEndDateTime === null
            ? heroListStandard.push(hero)
            : heroListPromo.push(hero)
    );

    const serverTimeRef = useRef<string>('');
    const [activeHero, setActiveHero] = useState<PromoContentProps | null>(
        heroListPromo[0] || heroListStandard[0] || ({} as GenericContentProps)
    );

    const shouldCallPromoServerTime = heroListPromo.length > 0;

    usePageIsVisible(() => shouldCallPromoServerTime && getPromoServerTime());

    /**
     * Gets time from server
     * @param force Grab the time from server even though it is already pulled
     */
    const getPromoServerTime = async (force: boolean = false) => {
        if (serverTimeRef.current === '' || force) {
            const datetime = await getPromoCurrentTime().then(
                data => data.datetime
            );
            serverTimeRef.current = datetime;
            updateActiveHero(true);
        }
    };

    const isPromo = ({ promoStartDateTime }: PromoContentProps) =>
        promoStartDateTime !== null;

    const isPromoActive = ({
        promoStartDateTime,
        promoEndDateTime,
        setToPtZone
    }: PromoContentProps) =>
        isTimeInRange(
            serverTimeRef.current,
            String(promoStartDateTime),
            String(promoEndDateTime),
            setToPtZone
        );

    const updateActiveHero = (refresh: boolean = false) => {
        const isActiveHeroNotPromo =
            activeHero && activeHero.promoStartDateTime === null;

        const promoHeroIndex = heroListPromo.findIndex(
            hero =>
                (isActiveHeroNotPromo || refresh) &&
                isPromo(hero) &&
                isPromoActive(hero)
        );

        if (promoHeroIndex > -1) {
            const hero = {
                ...heroListPromo[promoHeroIndex],
                promoCurrentTime: serverTimeRef.current,
                promoEndCallback,
                promoIsStart: promoHeroIndex === 0,
                promoRemainingLabel,
                promoStartLabel
            };
            setActiveHero(hero);
        } else if (refresh) {
            setActiveHero(heroListStandard[0] || ({} as GenericContentProps));
        }
    };

    const promoEndCallback = () => {
        setTimeout(() => {
            getPromoServerTime(true);
        }, 2000);
    };

    useEffect(() => {
        if (heroList) {
            updateActiveHero();
        }

        if (shouldCallPromoServerTime) {
            getPromoServerTime();
        }
    }, []);

    return (
        <>
            {!isEmptyObject(activeHero) && activeHero !== null && (
                <>
                    {activeHero.promoStartDateTime !== null ? (
                        serverTimeRef.current && (
                            <PromoHero {...(activeHero as PromoModuleProps)} />
                        )
                    ) : (
                        <DaypartStandardHero />
                    )}
                </>
            )}
        </>
    );
};

export default PromotionStandardHero;
