import { useEffect } from 'react';

import {
    DataLayerObject,
    updateDataLayer
} from '@tb-core/helpers/analytics/google';
import { interpolate } from '@tb-core/helpers/interpolate';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

/**
 * Will push the core datalayer attributes upon page load
 * @example
 *      usePageDataLayer();
 */
export const usePageDataLayer = (
    pageType: string = '',
    productName: string = ''
) => {
    const user = useUserContext();
    const { isLoggedIn } = user;
    const { pageControls } = usePageContext().content.pageData.layout;
    const seoTitle = usePageContext().content.pageData?.seoTitle;
    const userEnabled = pageControls?.indexOf('User') >= 0;

    const pageTitle = interpolate(seoTitle, {
        productName
    });

    useEffect(() => {
        const updatePageDataLayer = async () => {
            const data: DataLayerObject = { currentStore: 'Tacobell Site' };
            data.visitorStatus = !userEnabled
                ? 'unknown'
                : isLoggedIn
                ? 'logged in'
                : 'logged out';

            // Only set the visitorId if logged in
            if (isLoggedIn) {
                data.visitorId = user.customerID;
            }

            if (pageType && pageType !== '') {
                data.pageType = pageType;
            }

            if (pageTitle && pageTitle !== '') {
                data.pageTitle = pageTitle;
            }

            updateDataLayer(data);
        };
        if (typeof isLoggedIn === 'boolean' || !userEnabled) {
            updatePageDataLayer();
        }
    }, [isLoggedIn]);
};

export default usePageDataLayer;
