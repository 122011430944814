import { convertUTCDateTime } from '../browser/datetime';

/**
 * @param serverDateTime current datetime grabbed from the server
 * @param endDateTime datetime that promo will end
 * @returns object of the hours, minutes and seconds remaining between the two datetimes
 */
export const getTimeParts = (
    serverDateTime: string,
    endDateTime: string,
    setToPTZone?: boolean
): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
} => {
    const endTime = convertUTCDateTime(endDateTime, setToPTZone);
    // set serverDateTime string to date object with PT offset
    const serverTime = new Date(serverDateTime);
    const secondsCountDown = (endTime.getTime() - serverTime.getTime()) / 1000;

    return toCountdownParts(secondsCountDown);
};

/**
 *
 * Converts a number of miliseconds into the coresponding days / hours / minutes / seconds
 * @param {number} miliseconds
 * @returns {object} { days, minutes, hours, seconds }
 */
export const toCountdownParts = (miliseconds: number) => {
    const newDays = Math.floor(miliseconds / (3600 * 24));
    const newHours = Math.floor((miliseconds % (3600 * 24)) / 3600);
    const newMinutes = Math.floor((miliseconds % 3600) / 60);
    const newSeconds = Math.floor(miliseconds % 60);

    return {
        days: newDays,
        hours: newHours,
        minutes: newMinutes,
        seconds: newSeconds
    };
};

export const isTimeInRange = (
    serverTime: string,
    startTime: string,
    endTime: string,
    setToPtZone?: boolean
) => {
    const serverDate = new Date(serverTime);
    const startDate = convertUTCDateTime(startTime, setToPtZone);
    const endDate = convertUTCDateTime(endTime, setToPtZone);

    return (
        serverDate.getTime() > startDate.getTime() &&
        serverDate.getTime() < endDate.getTime()
    );
};
