export enum DatadogKey {
    APP_VERSION = 'Client.AppVersion',
    AUTH_CUSTOMER_TYPE = 'Auth.CustomerType',
    AUTH_KEY = 'AuthToken',
    AUTH_REQUEST_BODY = 'Auth.RequestBody',
    AUTH_RESPONSE_MESSAGE = 'Auth.ResponseMessage',
    CART_CODE = 'CartCode',
    CART_CODE_META = 'Client.CartCode',
    CLIENT_IP = 'ClientIP',
    CLIENT_IP_META = 'Client.IP',
    CLIENT_TYPE = 'ClientDeviceType',
    CLIENT_TYPE_META = 'Client.DeviceType',
    CLIENT_TYPE_WEB = 'Web',
    DD_EVENT_ID = 'ddEventId',
    ERROR_MESSAGE = 'error.message',
    ERROR_SOURCE = 'error.source',
    ERROR_TYPE = 'error.type',
    REASON = 'reason',
    REQUEST_BODY = 'RequestBody',
    REQUEST_HTTP_METHOD = 'RequestHTTPMethod',
    REQUEST_URL = 'RequestURL',
    RESPONSE_CODE = 'ResponseCode',
    SOURCE = 'source',
    STORE_ID = 'Payment.StoreId',
    TIMESTAMP = 'timestamp',
    TYPE = 'type',
    USER_EMAIL = 'UserEmail',
    USER_ID = 'UserId'
}

export enum DatadogDataStrategy {
    NONE = 'none',
    PASSWORD = 'password'
}

export enum DatadogValue {
    API_CALLS = 'API Calls',
    DEFAULT_HTTP_CODE = '4XX',
    DEFAULT_METHOD = 'GET',
    EMPTY_STRING = '',
    GUEST = 'Guest',
    MESSAGE_SEPARATOR = ' ',
    NETWORK = 'Network',
    NO_INFORMATION_VALUE = 'N/A',
    PASSWORD_PATTERN = 'password\\=\\S+',
    REGISTERED = 'Registered'
}

// @TODO: move into data dog handler when that helper is created.
export const emptyDataWrapper = {
    errorMessageTemplate: '',
    id: '',
    preEventAttributes: [],
    strategy: DatadogDataStrategy.NONE
};
