import { DatadogHandler } from '@tb-core/helpers/analytics/data-dog/handler';
import { devProxy } from '@tb-core/helpers/browser/dev-proxy';
import Fetch, { FetchOptions } from '@tb-core/helpers/fetch';
import { tbApiHost } from '@tb-core/helpers/next-env';
import { currentDatetimeUrl } from '@tb-core/next/api/urls';
import { PromoResponse } from '@tb-core/types/promo';

export const getPromoCurrentTime = async (): Promise<PromoResponse> => {
    const ddInstance = DatadogHandler.getInstance();
    const ddEventId = ddInstance.pushAndGetIdentifier(
        { type: 'customerDevice', source: 'Device Issues' },
        'Failed to get server time:'
    );

    const clientDateTime = () => ({
        datetime: new Date().toLocaleString('en-US', {
            timeZone: 'America/Los_Angeles',
            // Typescript was update to add longOffset, but not in our version
            // https://github.com/microsoft/TypeScript/issues/48391
            // @ts-ignore
            timeZoneName: 'longOffset'
        })
    });

    const request: FetchOptions = {
        method: 'GET',
        ...devProxy({
            host: tbApiHost,
            url: currentDatetimeUrl
        })
    };
    let res: Response;
    try {
        res = await Fetch(request);
    } catch (error) {
        console.error('Failed to get server time ', error);
        ddInstance.handleNetworkResponse(ddEventId, request, error as Error);
        return Promise.resolve(clientDateTime());
    }

    if (!res.ok) {
        ddInstance.handleNetworkResponse(ddEventId, request, res);
        return Promise.resolve(clientDateTime());
    }

    const date = await res.json();

    return {
        datetime: new Date(date.datetime).toLocaleString('en-US', {
            timeZone: 'America/Los_Angeles',
            // Typescript was update to add longOffset, but not in our version
            // https://github.com/microsoft/TypeScript/issues/48391
            // @ts-ignore
            timeZoneName: 'longOffset'
        })
    };
};
