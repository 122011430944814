import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { v4 as uuid } from 'uuid';

import {
    DatadogDataStrategy,
    DatadogKey,
    DatadogValue,
    emptyDataWrapper
} from '@tb-core/constants/data-dog';
import DatadogAttributesWrapper from '@tb-core/helpers/analytics/data-dog/attributes-wrapper';
import { FetchOptions } from '@tb-core/helpers/fetch';
import { storeNationalId, webFixedVersion } from '@tb-core/helpers/next-env';
import { GetStoreData, GetUser } from '@tb-core/helpers/storage';
import {
    DatadogAttribute,
    DatadogDataWrapper,
    DatadogDictionaryValue
} from '@tb-core/types/data-dog.d';

export class DatadogHandler {
    private static instance: DatadogHandler;
    private wrapperList: DatadogDataWrapper[] = [];
    public static getInstance(): DatadogHandler {
        if (!DatadogHandler.instance) {
            DatadogHandler.instance = new DatadogHandler();
        }
        return DatadogHandler.instance;
    }

    generateBaseAttributes() {
        const user = GetUser();
        const store = GetStoreData();

        if (user == null) {
            return [];
        }

        const { isLoggedIn, userUid } = user;

        return [
            {
                logName: DatadogKey.TIMESTAMP,
                metadataName: DatadogKey.TIMESTAMP,
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: new Date().toISOString()
            },

            {
                logName: DatadogKey.USER_ID,
                metadataName: DatadogValue.EMPTY_STRING,
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: userUid || DatadogValue.NO_INFORMATION_VALUE
            },
            {
                logName: DatadogKey.USER_EMAIL,
                metadataName: DatadogValue.EMPTY_STRING,
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: userUid || DatadogValue.NO_INFORMATION_VALUE
            },
            {
                logName: DatadogKey.AUTH_KEY,
                metadataName: DatadogValue.EMPTY_STRING,
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: 'n/a'
            },
            {
                logName: DatadogKey.CLIENT_IP,
                metadataName: DatadogKey.CLIENT_IP_META,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value: 'n/a'
            },
            {
                logName: DatadogKey.CART_CODE,
                metadataName: DatadogKey.CART_CODE_META,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value: 'n/a'
            },
            {
                logName: DatadogKey.CLIENT_TYPE,
                metadataName: DatadogKey.CLIENT_TYPE_META,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value: DatadogKey.CLIENT_TYPE_WEB
            },
            {
                logName: DatadogValue.EMPTY_STRING,
                metadataName: DatadogKey.APP_VERSION,
                shouldUseInLog: false,
                shouldUseInSession: true,
                value: webFixedVersion || DatadogValue.NO_INFORMATION_VALUE
            },
            {
                logName: DatadogValue.EMPTY_STRING,
                metadataName: DatadogKey.STORE_ID,
                shouldUseInLog: false,
                shouldUseInSession: true,
                value: store ? store.storeId : storeNationalId
            },
            {
                logName: DatadogValue.EMPTY_STRING,
                metadataName: DatadogKey.AUTH_CUSTOMER_TYPE,
                shouldUseInLog: false,
                shouldUseInSession: true,
                value: isLoggedIn ? DatadogValue.REGISTERED : DatadogValue.GUEST
            }
        ];
    }

    private generateErrorMessage(
        attributeSet: DatadogAttributesWrapper,
        savedData: DatadogDataWrapper,
        externalReason: string | undefined
    ): string {
        const url = attributeSet.getMatchingValue(DatadogKey.REQUEST_URL) || '';
        const template = this.isBlank(savedData.errorMessageTemplate)
            ? 'Device error occurred'
            : savedData.errorMessageTemplate;
        const code =
            attributeSet.getMatchingValue(DatadogKey.RESPONSE_CODE) ||
            DatadogValue.DEFAULT_HTTP_CODE;
        const method =
            attributeSet.getMatchingValue(DatadogKey.REQUEST_HTTP_METHOD) || '';
        const reason = externalReason || '';

        return [template, code, method, url, reason]
            .filter(element => !this.isBlank(element))
            .join(DatadogValue.MESSAGE_SEPARATOR);
    }

    generateNetworkAttributeList(
        request: FetchOptions,
        response: Response | Error | string | undefined
    ): DatadogAttribute[] {
        const { body, url } = request;
        const httpMethod = request.method || DatadogValue.DEFAULT_METHOD;
        const list: DatadogAttribute[] = [];

        if (body) {
            list.push({
                logName: DatadogKey.REQUEST_BODY,
                metadataName: DatadogKey.AUTH_REQUEST_BODY,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value: JSON.stringify(body)
            });
        }

        if (response instanceof Response) {
            list.push({
                logName: DatadogKey.RESPONSE_CODE,
                metadataName: '',
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: response.status
            });
        }

        if (url) {
            list.push({
                logName: DatadogKey.REQUEST_URL,
                metadataName: '',
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: url
            });
        }

        if (url && httpMethod) {
            list.push({
                logName: DatadogKey.REQUEST_HTTP_METHOD,
                metadataName: '',
                shouldUseInLog: true,
                shouldUseInSession: false,
                value: httpMethod
            });
        }

        return list;
    }

    handleGenericEvent(
        errorMessageTemplate: string,
        reason: string,
        attributes: { [key: string]: DatadogDictionaryValue } = {}
    ) {
        const processedList: DatadogAttribute[] = [];

        for (const [key, value] of Object.entries(attributes)) {
            processedList.push({
                logName: key,
                metadataName: key,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value
            });
        }

        const dataWrapper: DatadogDataWrapper = {
            errorMessageTemplate,
            id: '',
            preEventAttributes: processedList,
            strategy: DatadogDataStrategy.NONE
        };

        this.processEventAndSend(dataWrapper, reason, []);
    }

    handleEventWithAttributes(
        id: string | undefined,
        shouldSend: boolean,
        postEventAttributes: DatadogAttributesWrapper
    ) {
        const savedData = this.popAndReturnData(id);

        if (!shouldSend) {
            return;
        }

        this.processEventAndSend(
            savedData,
            postEventAttributes.reason,
            postEventAttributes.list
        );
    }

    handleNetworkResponse(
        id: string | undefined,
        request: FetchOptions,
        response: Response | Error
    ) {
        const list = this.generateNetworkAttributeList(request, response);
        let errorMessage = '';
        const shouldSendError =
            response instanceof Response ? !response.ok : true;

        if (response instanceof Error) {
            errorMessage = response?.toString() || DatadogValue.EMPTY_STRING;
        }

        const networkData = new DatadogAttributesWrapper(
            list,
            shouldSendError,
            errorMessage
        );

        this.handleEventWithAttributes(id, networkData.shouldSend, networkData);
    }

    isBlank(val: string | number) {
        if (typeof val === 'string') {
            return !val || /^\s*$/.test(val);
        }
        return false;
    }

    logDatadogEventByAttributes(
        message: string,
        attributes: { [key: string]: DatadogDictionaryValue }
    ) {
        datadogLogs.logger.error(message, attributes);
    }

    popAndReturnData(id: string | undefined): DatadogDataWrapper {
        const index = this.wrapperList.findIndex(element => element.id === id);
        const data = this.wrapperList[index];

        if (index < 0) {
            return emptyDataWrapper;
        }

        if (!data) {
            return emptyDataWrapper;
        }

        this.wrapperList.splice(index, 1);

        return data;
    }

    processEventAndSend(
        savedData: DatadogDataWrapper,
        externalReason: string | undefined,
        postEventAttributes: DatadogAttribute[] = []
    ) {
        // merge incoming data
        const attributeSet = new DatadogAttributesWrapper(
            this.generateBaseAttributes(),
            false
        );
        attributeSet.appendAttributes(savedData.preEventAttributes);
        attributeSet.appendAttributes(postEventAttributes);
        // process data and generate message
        const rawMessage = this.generateErrorMessage(
            attributeSet,
            savedData,
            externalReason
        );

        const message = {
            logName: DatadogKey.ERROR_MESSAGE,
            metadataName: DatadogKey.AUTH_RESPONSE_MESSAGE,
            shouldUseInLog: true,
            shouldUseInSession: true,
            value: rawMessage
        };

        attributeSet.appendAttributes(message);

        // handle type and source
        const savedType = attributeSet.getMatchingValue(DatadogKey.TYPE) || '';
        const savedSource =
            attributeSet.getMatchingValue(DatadogKey.SOURCE) || '';

        attributeSet.appendAttributes({
            logName: DatadogKey.ERROR_TYPE,
            metadataName: '',
            shouldUseInLog: true,
            shouldUseInSession: false,
            value: this.isBlank(savedType) ? DatadogValue.NETWORK : savedType
        });

        attributeSet.appendAttributes({
            logName: DatadogKey.ERROR_SOURCE,
            metadataName: '',
            shouldUseInLog: true,
            shouldUseInSession: false,
            value: this.isBlank(savedSource)
                ? DatadogValue.API_CALLS
                : savedSource
        });

        attributeSet.removeValue(DatadogKey.TYPE);
        attributeSet.removeValue(DatadogKey.SOURCE);

        // handle data strategy
        if (savedData.strategy === DatadogDataStrategy.PASSWORD) {
            try {
                const regex = new RegExp(DatadogValue.PASSWORD_PATTERN);
                const originalBody =
                    attributeSet.getMatchingValue(DatadogKey.REQUEST_BODY) ||
                    '';
                const modString = (originalBody as string).replace(regex, '');
                attributeSet.update(DatadogKey.REQUEST_BODY, modString);
            } catch (e) {
                console.error(e);
            }
        }

        // send data
        this.sendLogData(attributeSet, rawMessage);
        this.sendSessionData(attributeSet, rawMessage);
    }

    pushAndGetIdentifier(
        attributes: { [key: string]: DatadogDictionaryValue },
        errorMessageTemplate: string = '',
        strategy: DatadogDataStrategy = DatadogDataStrategy.NONE
    ): string {
        const innerUuid: string = uuid();
        const processedList: DatadogAttribute[] = [];

        for (const [key, value] of Object.entries(attributes)) {
            processedList.push({
                logName: key,
                metadataName: key,
                shouldUseInLog: true,
                shouldUseInSession: true,
                value
            });
        }

        this.wrapperList.push({
            errorMessageTemplate,
            id: innerUuid,
            preEventAttributes: processedList,
            strategy
        });

        this.updateDDSessionData();

        return innerUuid;
    }

    private sendLogData(
        attributeSet: DatadogAttributesWrapper,
        message: string
    ) {
        const logAttributes: {
            [key: string]: DatadogDictionaryValue;
        } = {};
        attributeSet
            .attributesInLogs()
            .forEach(
                element => (logAttributes[element.logName] = element.value)
            );
        this.logDatadogEventByAttributes(message, logAttributes);
    }

    private sendSessionData(
        attributeSet: DatadogAttributesWrapper,
        message: string
    ) {
        // sending session data
        this.updateDDSessionDataForSend(attributeSet.attributesInSession());

        const errorAttributes: {
            [key: string]: DatadogDictionaryValue;
        } = {};

        attributeSet
            .attributesInSession()
            .forEach(
                element =>
                    (errorAttributes[element.metadataName] = element.value)
            );

        this.uploadRUMError(message, errorAttributes);

        // remove data from RUM session
        for (const metaDataName of attributeSet.list) {
            const tbDatadogAttribute =
                this.generateBaseAttributes().find(
                    element =>
                        element.metadataName === metaDataName.metadataName
                ) || -1;
            if (tbDatadogAttribute > -1) {
                datadogRum.removeRumGlobalContext(metaDataName.metadataName);
            }
        }
    }

    updateDDSessionData() {
        const mergedAttributes: {
            [key: string]: DatadogDictionaryValue;
        } = {};

        this.generateBaseAttributes().forEach(value => {
            if (value.shouldUseInSession) {
                mergedAttributes[value.metadataName] = value.value;
            }
        });

        const userUid = GetUser()?.userUid;

        datadogRum.setUser({
            email: userUid || DatadogValue.NO_INFORMATION_VALUE,
            id: userUid || DatadogValue.NO_INFORMATION_VALUE
        });

        for (const [key, value] of Object.entries(mergedAttributes)) {
            datadogRum.addRumGlobalContext(key, value);
        }
    }

    updateDDSessionDataForSend(attributeSet: DatadogAttribute[]) {
        const userUid = GetUser()?.userUid;

        datadogRum.setUser({
            email: userUid || DatadogValue.NO_INFORMATION_VALUE,
            id: userUid || DatadogValue.NO_INFORMATION_VALUE
        });

        attributeSet.forEach(attr => {
            datadogRum.addRumGlobalContext(attr.metadataName, attr.value);
        });
    }

    uploadRUMError(
        message: string,
        attributes: { [key: string]: DatadogDictionaryValue }
    ) {
        datadogRum.addError(message, attributes);
    }
}
