import classNames from 'classnames';

import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import CountdownTimer from '@tb-core/components/container/countdown-timer';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import PageTitle from '@tb-core/components/simple/page-title';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { getTimeParts } from '@tb-core/helpers/server/datetime';
import { PromoPageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

// TODO: Refactor this component and StandardHeroContent as they are almost identical
const PromoHeroContent = ({
    colorTheme,
    description,
    entriesCollection,
    horizontalPosition = 'Left',
    promoCurrentTime,
    promoEndDateTime,
    promoIsStart,
    promoEndCallback,
    promoRemainingLabel,
    promoStartDateTime,
    promoStartLabel,
    setToPtZone,
    title,
    type,
    wordmark
}: PromoPageContentProps) => {
    const { Link } = entriesCollection?.items?.length
        ? entriesByTypename(entriesCollection?.items)
        : { Link: '' };
    const ctaLimit = 2;
    const dataLayerTemplate = {
        'Analytics-Action': type,
        'Analytics-Value': title
    };
    const ctaGroup = ctaGroupAdapter(colorTheme, dataLayerTemplate, Link);
    const theme = {
        color: colorTheme?.containerText
    };
    const titleTheme = {
        color: colorTheme?.titleText
    };
    const displayTitle = title && interpolate(title, tokenStrings);

    const { days, hours, minutes, seconds } = getTimeParts(
        String(promoCurrentTime),
        String(promoEndDateTime),
        setToPtZone
    );

    return (
        <>
            <FlexGridLayout
                align="stretch"
                className={classNames(
                    styles.content,
                    horizontalPosition &&
                        styles[horizontalPosition.toLowerCase()]
                )}
                direction="col"
                style={theme}
            >
                <PageTitle style={titleTheme}>
                    <ResponsiveImage {...wordmark} />
                    {displayTitle && (
                        <span
                            dangerouslySetInnerHTML={{ __html: displayTitle }}
                        />
                    )}
                </PageTitle>

                {/* Description Copy */}
                {description?.json && (
                    <div className={classNames(styles['promo-description'])}>
                        <ContentfulRichTextWithTheme
                            colorTheme={colorTheme}
                            node={description.json}
                            renderOptions={{ renderText }}
                        />
                    </div>
                )}

                {/* Countdown */}
                {promoStartDateTime !== null && (
                    <CountdownTimer
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        label={
                            promoIsStart ? promoStartLabel : promoRemainingLabel
                        }
                        colorText={colorTheme?.moduleUiActive}
                        countdownEndCallback={promoEndCallback}
                    />
                )}

                {/* CTA Group */}
                {ctaGroup && (
                    <FlexGridLayout
                        align="stretch"
                        className={classNames(styles['standard-hero-nav'], {
                            [styles.center]: horizontalPosition === 'Center',
                            [styles.single]: ctaGroup.length < 2
                        })}
                        justify="center"
                    >
                        <BrandedCtaGroup
                            ctaGroup={ctaGroup.slice(0, ctaLimit)}
                        />
                    </FlexGridLayout>
                )}
            </FlexGridLayout>
        </>
    );
};

export default PromoHeroContent;
