import { useEffect, useState } from 'react';

import DaypartAdapter from '@tb-core/adapters/contentful/daypart/daypart-adapter';
import StandardHero from '@tb-core/components/styled/banners/standard-hero';
import { currentTimeIsBetween } from '@tb-core/helpers/browser/datetime';
import { getDaypartTimeRange } from '@tb-core/helpers/daypart';
import usePageContext from '@tb-core/hooks/use-page-context';
import { GenericContentProps, GenericModuleProps } from '@tb-core/types';

const DaypartStandardHero = () => {
    const {
        contentGroupCollection,
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;
    const contentModules = contentGroupCollection.items;
    // @todo use GenericContentType.Hero instead when we figure out the build problem
    const heroList = contentModules.filter(
        module => module?.type?.toLowerCase() === 'hero'
    );
    const [activeHero, setActiveHero] = useState<GenericContentProps | null>(
        heroList.find(
            hero => hero.daypart === null && hero.promoStartDateTime === null
        ) || ({} as GenericContentProps)
    );
    const isDayparted = (dayparts: string | null) =>
        dayparts !== null && dayparts.length > 0;
    const updateActiveHero = (heroList: GenericContentProps[]) => {
        heroList.map(hero => {
            // whenever activeHero has no daypart, we want to
            // search for a dayparted one that falls into the time range based on browser local time
            if (
                activeHero &&
                activeHero.daypart === null &&
                isDayparted(hero.daypart)
            ) {
                const { start_time, end_time } = getDaypartTimeRange(
                    DaypartAdapter(hero.daypart),
                    daypartsConfig
                );

                if (
                    start_time &&
                    end_time &&
                    currentTimeIsBetween(start_time, end_time)
                ) {
                    setActiveHero(hero);
                }
            }
        });
    };

    useEffect(() => {
        if (heroList) {
            updateActiveHero(heroList);
        }
    }, [heroList]);

    return (
        <>
            {activeHero !== null && (
                <StandardHero {...(activeHero as GenericModuleProps)} />
            )}
        </>
    );
};

export default DaypartStandardHero;
