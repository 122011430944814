import { interpolate } from '@tb-core/helpers/interpolate';
import { devProxyUrl } from '@tb-core/next/api/urls';

interface DevProxy {
    url: string;
    host?: string;
    urlValues?: Record<string, any>;
}

/**
 * Map fetch requests server-side when in localhost to work around CORS
 * @return {}{ url, host }
 *         The host and url to map the request to
 * @example
 * const response = await Fetch({
 *   method: 'GET',
 *   ...devProxy({
 *     host: serverlessApiUrl,
 *     url: locationGeopointApi + query
 * })
 *
 */
export const devProxy = ({
    host,
    url = '',
    urlValues = {}
}: DevProxy): { host?: string; url: string } => {
    const isLocal = /localhost/.test(window?.location.hostname);
    const interpolatedUrl = interpolate(url, urlValues);

    // if local, unset host and return url of /api/dev-proxy{proxyUrl}
    if (isLocal) {
        let targetUrl = encodeURIComponent(host + interpolatedUrl);
        // add leading '/' to targetUrl for dev-proxy endpoint
        if (!targetUrl.startsWith('/')) {
            targetUrl = '/' + targetUrl;
        }
        return {
            url: interpolate(devProxyUrl, { url: targetUrl })
        };
    }

    return { host, url: interpolatedUrl };
};
