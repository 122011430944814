import PromotionHero from '@tb-core/components/container/banners/promotion-standard-hero';
import ContentModuleList, {
    contentModuleLookup
} from '@tb-core/components/simple/lists/content-module-list';
import usePageDataLayer from '@tb-core/hooks/analytics/use-page-datalayer';

import styles from './styles.module.scss';

const HomeMain = () => {
    usePageDataLayer();

    return (
        <main className={styles.main}>
            <PromotionHero />
            <ContentModuleList
                includeHero={false}
                modules={contentModuleLookup}
            />
        </main>
    );
};

export default HomeMain;
