import { useEffect } from 'react';

import {
    VISIBILITY_EVENT,
    VISIBILITY_STATE
} from '@tb-core/constants/page-visibility';

/**
 * Fire callback if page is visible
 * @param callback function to call if page is visible
 * @example
 *      usePageIsVisible(callback);
 */
export const usePageIsVisible = (callback: () => any) => {
    useEffect(() => {
        let terminatingEventSent = false;

        const onVisibleHandler = ({ type }: Event) => {
            if (terminatingEventSent) {
                return;
            }
            if (
                type === VISIBILITY_EVENT.FOCUS ||
                (type === VISIBILITY_EVENT.VISIBILITY_CHANGE &&
                    document.visibilityState === VISIBILITY_STATE.VISIBLE)
            ) {
                terminatingEventSent = true;
                callback();
            }
        };

        window.addEventListener('focus', onVisibleHandler);
        document.addEventListener('visibilitychange', onVisibleHandler);

        return () => {
            window.removeEventListener('focus', onVisibleHandler);
            document.removeEventListener('visibilitychange', onVisibleHandler);
        };
    }, [callback]);
};
